<template>
  <v-container grid-list-s text-xs-center class="pt-16">
    <v-layout wrap class="px-3">
      <v-row align="start" class="col-12">
        <h2
          class="text-h3  text-capitalize font-weight-black primary--text px-0"
        >
          {{ $t('legalNotice') }}
        </h2>
      </v-row>
      <v-row class="pa-4 ">
        <p>{{ legalNotice }}</p>
      </v-row>
    </v-layout>
  </v-container>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'

export default Vue.extend({
  computed: {
    ...mapGetters(['legalNotice'])
  },
  beforeMount() {
    this.getLegalNotice()
  },
  methods: {
    ...mapActions(['getLegalNotice'])
  }
})
</script>
